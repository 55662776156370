.swipeButtons {
  position: fixed;
  bottom: 10vh;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.swipeButtons .MuiIconButton-root {
  background-color: white;
  box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3) !important;
}

.swipeButtons__repeat {
  padding: 3vw !important;
  color: #f5b748 !important;
}

.swipeButtons__left {
  padding: 3vw !important;
  color: #ec5e6f !important;
}

.swipeButtons__star {
  padding: 3vw !important;
  color: #62b4f9 !important;
}

.swipeButtons__right {
  padding: 3vw !important;
  color: #76e2b3 !important;
}

.swipeButtons__lightning {
  padding: 3vw !important;
  color: #915dd1 !important;
}
