.chatScreen__message {
  display: flex;
  align-items: center;
  padding: 20px;
}

.chatScreen__text {
  margin-left: 10px;
  background-color: lightgray;
  padding: 15px;
  border-radius: 20px;
}

.chatScreen__timestamp {
  text-align: center;
  padding: 20px;
  color: gray;
}

.chatScreen__textUser {
  margin-left: auto;
  background-color: #29b3cd;
  padding: 15px;
  border-radius: 20px;
  color: white;
}

.chatScreen__input {
  display: flex;
  padding: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid lightgray;
}

.chatScreen__inputField {
  flex: 1;
  padding: 10px;
  border: none;
}

.chatScreen__inputButton {
    border: none;
    margin-right: 20px;
    font-weight: bolder;
    background-color: white;
    color: #fe3d71;
}